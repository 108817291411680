import {Main} from 'element-ui'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index.vue'
import RouteReplaceSelf from "@/utils/routeReplaceSelf";

Vue.use(VueRouter)
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/',
        name: '/',
        redirect: '/home', //
        component: Index,
    },
    {
        path: '/index',
        name: 'index',
        redirect: '/home', //
        component: Index,
        children: [

            {
                path: '/home',
                name: 'home',
                component: () =>
                    import('@/views/home/index.vue')
            },
            {
                path: '/mine',
                name: 'mine',
                component: () =>
                    import('@/views/mine/index.vue')
            },
            {
                path: '/exam',
                name: 'exam',
                component: () =>
                    import('@/views/exam/index.vue'),
            },
            {
                path: '/project',
                name: 'project',
                component: () =>
                    import('@/views/project/index.vue'),
            },
            {
                path: '/bbs',
                name: 'bbs',
                component: () =>
                    import('@/views/community/index.vue'),
            },
            {
                path: '/course/lists',
                name: 'course-lists',
                component: () =>
                    import('@/views/course/lists.vue'),
            },
            {
                path: '/course/detail',
                name: 'course',
                component: () =>
                    import('@/views/course/index.vue'),
            },
            {
                path: '/teacher/lists',
                name: 'teacher-lists',
                component: () =>
                    import('@/views/teacher/lists.vue'),
            },
            {
                path: '/news/lists',
                name: 'news-lists',
                component: () =>
                    import('@/views/news/lists.vue'),
            },
            {
                path: '/news/detail',
                name: 'news-detail',
                component: () =>
                    import('@/views/news/detail.vue'),
            },
            {
                path: '/teacher/list',
                name: 'teacher-list',
                component: () => import('@/views/teacher/lists.vue'),
            },
            {
                path: '/teacher/detail',
                name: 'teacher-detail',
                component: () => import('@/views/teacher/detail.vue'),
            },
        ]
    },

    {
        path: '/exam/paper',
        name: 'paper',
        component: () =>
            import('@/views/exam/paper/index.vue'),
    }

]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    //localStorage.clear();
    //to到哪儿  from从哪儿离开  next跳转 为空就是放行  
    if (to.path === '/login') {
        //如果跳转为登录，就放行 
        next();
    } else {
        //取出localStorage判断
        let token = localStorage.getItem('token');
        if (token == null || token === '') {
            // console.log('请先登录')
            //  next({ path: '/login' });

            next();
        } else {
            next();
        }
    }
});

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);

});

export default router