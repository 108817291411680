<template>
  <div class="header_wrap">
    <div class="container">
      <div class="header_left">
        <img class="logo" src="@/assets/image/logo.png" @click="goHome()" />
      </div>
      <div class="header_right">
        <!-- <i class="bell el-icon-bell" ></i> -->
        <img src="@/assets/image/icon_msg.png" class="bell el-icon-bell"  alt="">
        <el-popover placement="bottom" width="150" trigger="hover">
          <div style="display: flex; align-items: center; padding: 10px 0" @click="doOut">
            <img src="../../assets/image/login_out.png" alt="" />
            <span>退出登录</span>
          </div>
          <!-- <img class="avatars" slot="reference" :src="avatar" /> -->
          <div slot="reference" class="userInfo">
            <img class="avatars" src="@/assets/image/avatar.png" />
            <p> {{ company_name }} </p>
            <i class="el-icon-arrow-down"></i>
            <i class="el-icon-arrow-up"></i>
          </div>
        </el-popover>
      </div>
    </div>
    <nav>
        <div class="nav-box">
          <template v-for="(item, index) in navs" >
            <div :key="index" @click="changeActive(item)" class="nav-item" :class="{ 'nav-active': item.active }" v-if="index<6">
                <router-link :to="item.path">
                  <!--<img :src="item.active? item.iconActive: item.icon" alt="" />-->
                  <span>{{ item.title }}</span>
                  <!-- <aside v-if="item.num">{{ item.num }}</aside> -->
                </router-link>
            </div>
            <div :key="index"  class="nav-item more" :class="{ 'nav-active': item.active }" v-if="index == 7">
                <span>...</span>
                <div class="more-menu">
                  <ul class="menu-box">
                    <template v-for="(item, index) in navs" >
                      <li :key="index" class="item" v-if="index>5">
                        <router-link :to="item.path">{{item.title}}</router-link></li>
                    </template>
                  </ul>
                </div>
            </div>
          </template>
          <div class="curse"  v-if="isShow">
            <ul class="catalog">
              <li v-for="(item,index) in homeOptions" :key="index" class="item" @mouseenter="handleMouseEnter(index)">
                {{item.title}}<span class="number" v-if="item.num">( {{ item.num }}</span>
                <i class="el-icon-arrow-right"></i> 
              </li>
            </ul>
            <div class="secondary" :style="{'height':secondaryHeight}">
              <div class="item" v-for="(item,index) in chooseOptions" :key="index">
                {{ item.title }}
              </div>
            </div>
          </div>
          <!-- v-if="isShow && homeOptions[chooseIndex].num > 0" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" -->
          <!-- <div class="secondary" :style="{'height':secondaryHeight}">
            <template v-if="chooseIndex!= -1 && homeOptions[chooseIndex].num > 0">
              <div class="item" v-for="(item,index) in homeOptions[chooseIndex].options" :key="index">
                {{ item.title }}
              </div>
            </template>
          </div> -->
        </div>
      </nav>
  </div>
</template>

<script>
// 引入接口

import default_avatar from '@/assets/image/avatar.png'

import { mapGetters } from 'vuex'
// import { logout } from "@/api/login/index";


export default {
  data () {
    return {
      avatar: default_avatar,
      company_name: localStorage.getItem("company_name") || '逍遥',
      navs: [
        {
          title: "首页",
          name: 'home',
          path: '/home',
          icon: '',
          iconActive: '',
          num: 8,
          active: false
        },

        {
          title: "学习中心",
          name: 'mine',
          path: '/mine',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },


        {
          title: "课程中心",
          name: 'course-lists',
          path: '/course/lists',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },

        {
          title: "讲师中心",
          name: 'teacher-lists',
          path: '/teacher/list',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },


        {
          title: "项目培训",
          name: 'project',
          path: '/project',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },

        {
          title: "新人培训",
          name: 'pay',
          path: '',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
        {
          title: "知识库",
          name: '',
          path: '',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
        {
          title: "新闻中心",
          name: 'pay',
          path: '',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
        {
          title: "课程专题",
          name: 'pay',
          path: '',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
        {
          title: "积分商城",
          name: 'pay',
          path: '',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
        {
          title: "学习社区",
          name: 'bbs',
          path: '/bbs',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
        // {
        //   title: "...",
        //   name: '',
        //   path: '/',
        //   icon: '',
        //   iconActive: '',
        //   num: 0,
        //   active: false
        // },
      ],
      homeOptions:[
        {
          title: "通用课程",
          name: 'home',
          path: '/home',
          icon: '',
          iconActive: '',
          num: 8,
          options: [
            {
              title: "通用课程",
              name: 'home',
              path: '/home',
              icon: '',
              iconActive: '',
              num: 0,
            }
          ]
        },
        {
          title: "平台介绍",
          name: 'home',
          path: '/home',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
        {
          title: "压力管理",
          name: 'home',
          path: '/home',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
        {
          title: "人力资源",
          name: 'home',
          path: '/home',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
        {
          title: "销售管理",
          name: 'home',
          path: '/home',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
        {
          title: "Office",
          name: 'home',
          path: '/home',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
      ],
      options: [],
      msgNum: 0,
      chooseIndex:-1,
      secondaryHeight:0,
      isShow:true,
      chooseOptions:[]
    };
  },


  computed: {
    ...mapGetters([
      'userInfo',
      'isLogin',
    ]),
  },



  created () {
    this.avatar = localStorage.getItem("avatar");
    console.log(this.$route    ,222222222222)

    if(this.$route.path == '/home'){
          this.isShow = true
        }else{
          this.isShow = false
        }
    // this.changeActive();
    this.initNav();

  },
  mounted () {
    
  },


  watch: {
    $route: {
      handler: function (val, oldVal) {
        if(val.path == '/home'){
          this.isShow = true
        }else{
          this.isShow = false
        }
        this.initNav();
      },
      // 深度观察监听
      deep: true
    },
    "$store.state.user.user_type": {
      //深度监听设置为
      deep: true,//
      handler: function (newVal, oldVal) {
        //修改数据时，能看到输出结果
        console.log(oldVal, newVal);
        this.initNav();

      }
    }
  },


  methods: {
    handleMouseEnter(index) {
      this.chooseOptions  = this.homeOptions[index].options
      this.secondaryHeight = this.homeOptions[index].options?.length>0?'322px':0
      // if(index== 0 || index>0){
      //   this.chooseIndex = index
      // }
      // this.isShow = true
    },
    handleMouseLeave(e,index) {
      console.log('鼠标移出',e)
      // this.chooseIndex = -1
      this.isShow = false
    },
    initNav () {


      var has=false;
      this.navs.forEach((item) => {
        if(item.path == this.$route.path){
          item.active=true;
          has=true;
          //continue;
        }else {
           item.active=false;
         
        }
       // item.active = (item.path == this.$route.path || this.$route.path.includes(item.name) ? true : false);
      });

      if(has==false){
        this.navs.forEach((item) => {
         item.active = (item.path == this.$route.path || this.$route.path.includes(item.name) ? true : false);

      });
      }

    },


    changeActive (nav) {
      var that = this;
      // this.navs.forEach((item) => {
      //   if (item.path == nav.path) {
      //     console.log(item.path)
      //     item.active = true;
      //   } else {
      //     item.active = false;
      //   }
      // });
      this.$router.push({
        path:nav.path
      })
    },


    goHome () {
      this.$router.push({ name: "index" });
    },
    homes () {
      this.$router.push({ name: "Home" });
    },
   
    doOut () {
      let data = {};
      // logout(data).then((res) => {
      //   if (res.code == 200) {
      //     this.$message.success("退出成功");
      //     //清除token
      //     localStorage.removeItem("token");
      //     localStorage.clear();
      //     //跳转
      //     this.$router.push({ name: "login" });
      //   }
      // });
    },
   

   
  },
  components: {},
};
</script>

<style lang='scss' scoped>
.header_wrap {
  background-color: #fff;
  height: 155px;
  .container {
    height: 100px;
    display: flex;
    justify-content: space-between;
    .header_left {
      display: flex;
      align-items: center;
      .logo {
        width: 230px;
        height: 60px;
      }
    }
    
    .msg_box {
      margin-left: 20px;
      padding: 0 20px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 18px;
      }
    }
    .header_right {
      display: flex;
      align-items: center;
      font-size: 16px;
      .bell{
        width: 22px;
        height: 22px;
        font-size: 22px;
      }
      .userInfo{
        display: flex;
        align-items: center;
        cursor: pointer;
        p {
          color: #333;
        }
        img {
          margin-left: 24px;
          width: 34px;
          height: 34px;
          border-radius: 17px;
          margin-right: 10px;
        }
        .el-icon-arrow-up{
          display: none;
        }
        &:hover .el-icon-arrow-down{
          display: block;
        }
      }
      
    }
    aside {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 2px;
      right: 0;
      color: #fff;
      background-color: #ff4e4e;
      border-radius: 10px;
      line-height: 20px;
      font-size: 12px;
      text-align: center;
    }
  }
  nav {
    display: flex;
    justify-content: center;
    height: 55px;
    background-color: #4c5358;
    .nav-box {
      display: flex;
      width: 1200px;
      position: relative;
      .curse{
        position: absolute;
        top:55px;
        left: 0;
        &:hover .secondary{
          display: block;
        }
        .catalog{
          width: 210px;
          height: 322px;
          background-color: rgba(0,0,0,.3);
          font-size: 16px;
          font-weight: 400;
          color: #fff;
          .item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            height: 16.66667%;
            padding: 0 42px;
            cursor: pointer;
            .number{
              display: inline-block;
            }
            &:hover{
              background: #fff;
              color: #333;
            }
          }
        }
        .secondary{
          position: absolute;
          top: 0;
          left: 210px;
          width: 740px;
          height: 322px;
          background: #fff;
          padding: 0 20px;
          box-sizing: border-box;
          display: none;
          .item{
            width: 100%;
            min-height: 16.66667%;
            line-height: 55px;
            border-bottom: 1px solid #eee;
            font-size: 16px;
          }
        }
      }
      .nav-item{
        width: 150px;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        &:nth-child(1){
          width: 210px;
        }
        &:hover{
          background: #266fff;
        }
      }
      a {
        position: relative;
        // padding: 0 15px;
        line-height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .more{
      &:hover .more-menu{
        display: block;
      }
      .more-menu{
        position: absolute;
        width: 150px;
        background-color: #4c5358;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.09);
        top:56px;
        right: 0;
        display: none;
        z-index: 9999;
       
        .menu-box{
          width: 100%;
          .item{
            font-size: 18px;
            width: 100%;
            height: 55px;
            line-height: 55px;
            &:hover{
              background: #266fff;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

/deep/ .el-menu-item.is-active {
  color: white !important;
  background-color: #266fff !important;
}
/deep/ .el-cascader .el-input {
  width: 90px;
  border: none !important;
  margin-right: 20px;
}
/deep/ .el-cascader .el-input .el-input__inner {
  color: white;
  border: none;
  background-color: transparent;
}
/deep/ .el-popover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-active {
  background: #266fff;
}
.nav-active:hover{
  background: #266fff;
}

</style>
