<template>
  <div class="page">
    <!-- 顶部 -->
    <headers></headers>
    <!-- 主体 -->
    <div class="main_box">
      <router-view />
    </div>
    <!-- 底部 -->
    <footers></footers>
  </div>
</template>

<script>
// 引入顶部和底部组件
import headers from "@/components/header/header.vue";
import footers from "@/components/footer/footer.vue";
export default {
  data () {
    return {

    }
  },
  created () { },
  mounted () { },
  methods: {},
  // 应用组件
  components: { headers, footers },
};
</script>

<style scoped>
.main_box {
  min-height: calc(100vh - 160px);
  background-color: #e9eaeb;

}
</style>



