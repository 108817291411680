import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.use(ElementUI, { size: 'small' });
//预览图片
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)

Viewer.setDefaults({
    Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/common.css';
// import '../utils/rem'
// import AMap from 'vue-amap';
// Vue.use(AMap);
// // 初始化vue-amap
// AMap.initAMapApiLoader({
//   // 高德key
//   key: '31319b17926ba8c72e8f93580492719b',
//   // 插件集合 （插件按需引入）
//   plugin: ['AMap.CitySearch','AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','AMap.Geolocation'],//依赖配置，根据自己的需求引入
// });


import urlConfig from '@/api/config'
// 全局方法挂载
Vue.prototype.wsUrl = urlConfig.prod.wsURL


import ws from '@/utils/ws' 
Vue.prototype.ws = ws

import { file } from '@/utils/file'
Vue.prototype.$download = file.download
Vue.prototype.$downloadUrl = file.openUrl

import { common } from '@/utils/common'
Vue.prototype.$goTo = common.goTo
Vue.prototype.$goUserHome = common.goUserHome
Vue.prototype.$goChat = common.goChat

Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')